.social-footer {
  background-color: #171714;
  height: 44px;
  position: absolute;
  width: 100%;
  overflow: hidden;

  .social-footer-content {
    margin-top: 6px;
    width: 100%;
    font-family: 'Sequel100Black65', sans-serif;
    font-size: 16px;
    text-transform: initial;
    text-align: center;
  }
  .socialBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    .mssDarts {
      padding-top: 6px;
    }
  }
  .availableBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30%;
  }
  .plutoTVLogoInner {
    text-transform: uppercase;
    padding-top: 6px;
    position: relative;
    .plutoTVLogo {
      position: absolute;
      right: -122px;
      top: -48px;
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  .social-icon {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    img {
      width: 100%;
    }
  }

  .fb-icon {
    transform: translateX(-3px);
    margin-right: 30px;
  }
}

.tv-banner {
  position: relative;
  overflow: hidden;

  .banner {
    width: 100%;
    height: 41px;
    opacity: 1;
  }
}
