.mss-news-container {
  width: 100%;
  margin-bottom: 20px;

  .ui.card, .ui.cards {
    margin: 20px 50px 35px 0 !important;

  }
}

.mss-news-item {
  width: 100% !important;
}

.mss-news-body {
  max-height: 400px;
  padding: 10px;
}

.mss-table-title {
  max-width: 250px;
  word-break: break-all;
}

.mss-news-img {
  max-width: 250px;
}

.newsImg {
  max-width: 150px;
}

.mss-btn-wrapper {
  position: absolute;
  display: flex;
  column-gap: 10px;
  right: 5px;
  margin-top: 40px;
  border-top: none !important;

  .ui.table tr td {
    border-top: none !important;
  }
}

.mss-btn-edit {
  padding-top: 8px !important;
  width: 66px;
  height: 28px;
  font-size: 12px !important;
}

.mss-top-controls {
  display: flex;
  justify-content: space-between;
}

.mss-table {
  margin-top: 12px !important;
  position: relative !important;

  &-header {
    font-family: "Sequel100Black75", sans-serif;
  }

  &-body {
    font-family: "Montserrat", sans-serif;
  }
}

@media (max-width: 1345px) {
  .mss-news-container {
    display: block;
    padding-left: 0;
    max-width: 80%;
  }
}


