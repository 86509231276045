.filter-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(42, 40, 40, 0.356);
  z-index: 1200;
}
.cms-filter {
  width: 400px;
  background: white;
  padding: 20px;
  border-radius: 15px;
    z-index: 1200;
  &__btn-inner {
    display: flex;
    justify-content: center;
  }
}
.flat-picker-wrapper {
  position: relative;
  .reset-btn {
    position: absolute;
    right: 0;
    top: 15px;
    color: black;
    text-decoration: underline;
    outline: none;
    background: transparent;
    font-size: 12px;
    border: none;
    cursor: pointer
  }
}