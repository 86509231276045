.player-name {
  &__shoot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__score {
    font-size: 24px;
    font-weight: bold;
    margin: 0 .5rem;
  }
  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 100%;
    background-color: #ffe161;
    text-align: center;
    line-height: 1.6rem;
  }
  &__shoot-details {
    font-size: 18px;
    text-align: center;
  }
}

.column {
  span {
    font-size: 18px;
  }
}
