.page {
  background: rgba(43, 43, 43, 0.95);
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
}

.filters {
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: #FFFFFF;
}

.tableInner {
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 20px;
}

.pagination {
  margin-top: 20px;
}

.controlBtn {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer
}
.refresh {
  outline: none;
  border: none;
  background: transparent;
}