.shootOut-form-wrapper {
  overflow: scroll;
  width: 640px !important;
  height: 480px;

  .btn-submit {
    display: flex;
    justify-content: center;
  }
}

.dartsGame-form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
}

.half-width-field {
  width: 48%;
  position: relative;
}

.temporary-message {
  font-size: 14px;
  position: absolute;
  color: rgba(255, 0, 0, 0.61);
  right: 0;
}
.shoot-out-small-field {
  width: 31%;
}
