.pause-pop-up {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(40px);
  background: rgba(31, 31, 31, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .pop-up-content {
    width: 1000px;
    height: 400px;
    background: #c6a81c;
    border-radius: 20px;
    padding: 60px;
    font-size: 80px;
    color:#211f20;
    font-family: "BebasPro", sans-serif;
    text-transform: uppercase;
    .pop-up-text-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .pop-up-text {
        margin-bottom: 0;
      }
    }
  }
}