.cms-action-btns-inner {
  display: flex;
  justify-content: flex-end;
  .button {
    margin: 0 5px !important;
  }
}
.darts-game-form {
  width: 640px;
  height: 480px;
  overflow: scroll;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
}

.darts-game-adc-label {
  margin-bottom: 3px !important;
}

.dartsGame-form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.half-width-field {
  width: 48%;
  position: relative;
}

.temporary-message {
  font-size: 14px;
  position: absolute;
  color: rgba(255, 0, 0, 0.61);
  right: 0;
}
