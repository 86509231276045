.ui.green.card:first-child {
  margin: 1em 0 !important;
}
.ui.card:first-child {
  margin-top: 10px !important;
}
.ui.green.card:last-child {
  margin: 1em 0 !important;
}
.ui.card:last-child {
  margin-top: 10px !important;
}