.permissions {
  background: rgba(158, 158, 158, 0.16);
  padding: 15px;
  margin-bottom: 20px;
}

.title {
  text-align: center;
  margin: 0;
}

.permission {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}