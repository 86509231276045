@media (max-width: 1366px) {
  .static-statistic-names {
    font-size: 50px;
  }
  .static-statistic-item {
    height: 30px;
  }
  .static-statistic-item-title {
    font-size: 15px;
  }
  .static-statistic-item-value {
    font-size: 30px;
  }
  .static-statistic-value-part {
    font-size: 23px !important;
  }
}

@media (max-width: 1667px) {
  .static-statistic-names {
    font-size: 60px;
  }
  .static-statistic-item {
    height: 50px;
  }
  .static-statistic-item-title {
    font-size: 20px;
  }
  .static-statistic-item-value {
    font-size: 30px;
  }
  .static-statistic-value-part {
    font-size: 23px !important;
  }
}

@media (min-width: 1667px) and (max-width: 1919px) {
  .static-statistic-names {
    font-size: 80px;
  }
  .static-statistic-item {
    height: 70px;
  }
  .static-statistic-item-title {
    font-size: 25px;
  }
  .static-statistic-item-value {
    font-size: 38px;
  }
  .static-statistic-value-part {
    font-size: 28px !important;
  }
}

@media (min-width: 1919px) and (max-width: 1921px) {
  .static-statistic-item {
    height: 80px;
  }
}

@media (min-width: 2559px) {
  .static-statistic-container {
    margin-top: 150px;
  }
  .static-statistic-item {
    height: 100px;
  }
}

@media (max-height: 1100px) {
  .static-statistic-logo  {
    position: sticky;
    transform: initial;
    margin: 10% auto;
  }
  .static-statistic {
    overflow: scroll !important;
  }
}
