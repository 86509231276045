.btnWrapper {
  display: flex;
  justify-content: space-between;
}
.card {
  background: inherit !important;
  .link {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
.notActive {
  background: rgba(158, 158, 158, 0.29) !important;
}