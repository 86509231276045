.scoreboard-page {
  background-color: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.shootout > .ui.grid > .row > .column {
  display: flex !important;
}
.scores-column {
  justify-content: flex-end;
}

.scores-column-item {
  padding-right: 35px;
}
