.player-vs-player {
  position: relative;
  width: 85vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#particle-container {
  width: 227%;
  height: 106%;
  position: absolute;
  top: 0;
  right: -36%;
  opacity: 0.8;
}



@for $i from 1 through 30 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
    }
  }

  .particle:nth-child(#{$i}){
    animation: particle-animation-#{$i} 10s infinite;
    $size: random(5) + 30 + px;
    opacity: random(100)/100;
    height: $size;
    width: $size;
    animation-delay: -$i * .2s;
    transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
    background: rgba(198, 168, 28, 0.11);
  }
}
.black-background {
  width: 105%;
  height: 100%;
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
}