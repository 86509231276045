.table {
  width: fit-content;
  max-width: 624px;
  min-width:624px ;
  .header {
    background: #1D1D1B;
    height: 23px;
    display: flex;
    justify-content: space-between;

    .headerColumn {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: "Sequel100Black65", sans-serif;
      width: 100%;
    }
  }
  .valuesRow {
    background: #FFFFFF;
    height: 63px;
    margin-top: 8px;
    color: #1D1D1B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .valueCell {
      display: flex;
      font-family: "Sequel100Black75", sans-serif;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;
      text-align: center;
      width: 100%;
      &:after {
        content: "";
        width: 2px;
        height: 70%;
        position: absolute;
        right: 0;
        background: #808385;
        opacity: .2;
      }
    }
    .valueCell:last-child:after {
      content: "";
      width: 0;
    }
  }
}