$bg-color: black;
.match-stats-shoot-out {
  font-family: "BebasKai";
  padding-bottom:15%;
  height: 100vh;
  width: 100%;
  background-color: $bg-color;
  color: invert($color: $bg-color);
  display: flex;
  justify-content: center;
  .panel-column {
    z-index: 1;
    padding-top: 30px;
    margin-left: 30px;
    .logo-container {
      text-align: center;
    }
    .logo {
      height: 110px;
    }
    .panel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .column-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 8px 5px;
        margin-top: -5px;
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          margin: 0.2rem;
          span {
            display: block;
            font-size: 20px;
          }
          height: 3.5rem;
        }
      }
      .statistics-titles {
        font-family: "Bison";
        color: #c6a81c;
        padding-top: 60px;
      }
      .player-statistics {
        width: 120px;
        .player-name {
          font-family: "Bison";
          font-size: 30px;
          color: #c2c2c2;
          margin: 20px;
          margin-top: 0;
          text-transform: uppercase;
          white-space: nowrap;
        }
        div {
          color: black;
          font-size: 25px;
          font-family: "BebasKai";
          background: linear-gradient(to bottom, #eeeeee, #e9e9ea 90%, #9e9e9e);
          width: 65px;
          border-radius: 5px;
        }
      }
    }
  }
  .content-column {
    z-index: 0;
    .shoot-out-content{
      width: 1200px;
      max-width: 100%;
      background-color: #283747;
    }
    .content {
      width: 1200px;
      max-width: 100%;
      background-color: #283747;
      padding-bottom: 40px;

      .border-container {
        padding-left: 20px;
        padding-right: 15px;
        padding-top: 15px;

        .game-scores-wrapper {
          width: 100%;
          margin-bottom: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
  }
  .not-found {
    margin: 2rem;
    color: invert($color: $bg-color);
    font-weight: bolder;
    font-size: xx-large;
  }
}
.match-stats-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-color;
  color: white;
}

.counter {
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  background-color: black;
  max-width: 200px;
  width: 100%;
  height: 135px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &__scoreboard {
    background: linear-gradient(#f6c34b, #f7c551);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-bottom: 2px;
    border-radius: 10px;
    &__value {
      font-size: 50px !important;
      color: $bg-color;
    }
  }
  &__title {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
}

