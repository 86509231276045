.action-buttons { 
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  
  &__row {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    
    button {
      width: 23%;
      margin: 0!important;
    }

    .end-game-btn {
      background-color: #790000!important;
      color: white!important;
    }
  }
}