.scoreboard {
  width: 1000px !important;
  background: inherit !important;
  height: fit-content;

  &_body {
    max-height: calc(100vh - 170px);
    overflow: auto;
    scroll-behavior: auto;
  }
}

.shootout {
  .scoreboard_header {
    min-height: 140px;

    .name-col {
      height: 95px;
    }
  }
}
