.players-stats {
  width: 85vw;
  height: 85vh;
  backdrop-filter: blur(3px);
  position: relative;
  z-index: 110;

}
.player-photo {
  position: absolute;
  width: 50%;
  height: 100vh;

  &__left-position {
    left: -12%;
    z-index: 1000;
  }

  &__right-position {
    right: -16%;
    z-index: 1000;
  }

  .player-img-inner {
    position: relative;
    width: 100%;
    height: 100%;
    .player-img {
      max-width: 100%;
      height: auto;
      z-index: 1000;

      &__left-position {
        left: -200px;
      }

      &__right-position {
        right: -200px;
      }
    }
  }
}