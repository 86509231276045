.post-match-wrapper {
  .static-statistic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background: url('../../img/staticStatisticBg.png') no-repeat center center/cover;
    position: absolute;
    overflow: hidden;

    &-logo img {
      margin-top: 50%;
    }
    &-total-points {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #BF9C30;
      font-size: 152px;

      &-wrapper {
        height: 170px;
        line-height: 180px;
        background-color: rgba(29, 29, 27, 0.5);
        align-items: center;
        margin: 0 30px;

      }
    }

    &-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: 85%;
      padding: 0 70px;
    }

    &-names {
      font-family: 'DharmaGothicM-ExBold', sans-serif;
      font-size: 95px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      text-transform: uppercase;
      &-firstname {
        color: #BF9C30;
      }
      &-lastname {
        color: white;
        display: inline-block;
        padding-left: 10px;
      }
    }

    &-container {
      margin-top: 65px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    &-item {
      height: 85px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      color: #FFCF40;
      margin-top: 13px;
      padding: 0 78px;

      &-even {
        background: #2B2B2B 0% 0% no-repeat padding-box;
      }

      &-odd {
        background: #232321 0% 0% no-repeat padding-box;
      }

      &-title {
        text-align: center;
        font-family: 'Sequel100Black65', sans-serif;
        font-size: 32px;
        color: #808385;
      }

      &-value {
        font-family: 'Sequel100Black75', sans-serif;
        font-size: 46px;
        width: 18%;
        white-space: nowrap;


        &-away {
          text-align: end;
          white-space: nowrap;
        }

        span {
          font-family: 'Sequel100Black75', sans-serif;
          font-size: 40px;
        }
      }
    }

    &-logo {
      width: 100%;

      img {
        display: block;
        width: 115px;
        height: 58px;
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
      }
    }
  }
}
.fixed-value {
  display: inline-block;
  font-size: 46px;
}
