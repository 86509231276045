.date-picker-btn{
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  padding-bottom: 30px;
}
.phase-form {
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
  min-width: 500px;

}
.phase-form-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
