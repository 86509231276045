@media (max-width: 1441px) {
  .broadcaster-logo-igm {
    display: block;
    transform: translateY(48%);
    width: 85%;
    height: 80%;
    margin-bottom: 100px;
  }
  .red-dragon-logo-inner {
    right: -8%;
  }
  .red-dragon-logo {
    width: 60%;
    margin-top: 50px;
  }
  .broadcaster-logo {
    width: 70%;
  }
}

@media (min-width: 1442px) and (max-width: 1534px) {
  .broadcaster-logo-inner__left-side {
    bottom: 15px;
  }
  .broadcaster-logo-igm {
    display: block;
    transform: translateY(35%);
    margin-bottom: 65px;
  }
  .red-dragon-logo-inner {
    bottom: -20px;
    right: -8%;
  }
}

@media (min-width: 1535px) and (max-width: 1678px) {
  .broadcaster-logo-igm {
    display: block;
    transform: translateY(50%);
    height: 80%;
    width: 85%;
    margin-bottom: 90px;
  }
}

@media (min-width: 1679px) and (max-width: 1918px) {

  .broadcaster-logo-igm {
    transform: translateY(17%);
    height: 80%;
    width: 85%;
  }
  .broadcaster-logo-inner {
    bottom: 55%;
  }
  .red-dragon-logo-inner {
    right: -6%;
    bottom: -20px;
  }

}

@media (min-width: 1919px) and (max-width: 2250px) {
  .broadcaster-logo-igm {
    display: block;
    //transform: translateY(15%);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -39%);
    width: 85%;
    height: 80%;
    //margin-bottom: 25px;
    position: absolute;
  }
  .broadcaster-logo-inner__left-side {
    bottom: 20px;
  }
  .red-dragon-logo-inner {
    bottom: -15%;
  }
}

@media (min-width: 2559px) {
  .broadcaster-logo-igm {
    transform: translateY(-38%);
    width: 85%;
    height: 80%;
    margin-top: 45px;
  }
}
