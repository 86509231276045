.league-table-page {
  background-color: black;
  min-height: 100vh;

  .logo {
    opacity: 0.85;
    width: 400px;
    margin: 35px;
  }

  .img-container {
    padding: 25px;
    text-align: center;
    display: block;
  }

  .league-table-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .league-table-cell-plus-minus {
    transform: translateX(-12px) !important;
  }
}


