.pre-match-player-names {
  position: absolute;
  color: white;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 30px;
  display: flex;
  align-items: baseline;

  .pre-match-player {
    width: max-content;
    &__name, &__surname {
      font-size: 120px;
      font-family: DharmaGothicM-ExBold, sans-serif;
      line-height: 120px;
      padding-left: 25px;
      padding-right: 25px;
      text-align: center;
      text-transform: uppercase;
    }
    &__surname {
      color: #c6a81c;
      text-shadow: -2px -14px 20px #000000;
      line-height: 10px;
    }
  }

  .pre-match-player-vs {
    font-size: 120px;
    font-family: DharmaGothicM-ExBold, sans-serif;
    margin: 0;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

