.cms-action-btns-inner {
  display: flex;
  justify-content: flex-end;
  .button {
    margin: 0 5px !important;
  }
}

.darts-game-form {
  width: 800px;
  height: 600px;
  overflow: scroll;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
}
.leg-to-win-field {
  position: relative;
}
.double-start {
  display: flex;
  margin-top: 15px;
  margin-left: 40px;
  input {
    margin-left: 20px;
  }
}

.create-game-submit {
  display: flex;
  justify-content: center;
}
.table-controls-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dartsGame-form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.dartsGame-form-legs {
  display: flex;
  align-items: center;
}

.half-width-field {
  width: 48%;
  position: relative;
}

.temporary-message {
  font-size: 14px;
  position: absolute;
  color: rgba(255, 0, 0, 0.61);
  right: 0;
}
