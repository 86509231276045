.league-table-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.table-container {
  padding-top: 20px;
  max-width: 750px;
}
.title-container {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.download-stats-btn {
  position: absolute;
  top: 40%;
  right: 0;
  transform: translate(0, -50%);

  .download-png-stats-btn {
    border-radius: 18px !important;
  }
}

.title {
  background-color: #aa8d2dff;
  opacity: 0.9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

@media screen and (max-width: 767px) {
  .league-table {
    width: 100vh !important;
  }
  .logo {
    height: 300px !important;
  }
}

.league-table {
  color: white;
  padding: 0;
  text-align: center !important;
}

.table-header-wrapp {
  padding: 5px 0 0 438px;
  font-family: 'Sequel100Black65', sans-serif;
  font-size: 13px !important;
  display: flex;
  background: #808385;
  height: 28px;
}

.league-table-body {
  margin-top: 5px;
}

.league-table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.league-table-cell {
  width: 44px;
  padding-right: 10px;
  margin-left: 23px;

  &-plus-minus {
   transform: translateX(-7px);
  }
  &-pts {
    transform: translateX(-27px);
  }
}

.cell-padding {
  font-family: 'Sequel100Black65', sans-serif;
  font-size: 18px;
  height: 101%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  color: #808385 !important;
  background-color: #1D1D1B;
  &-index {
    font-family: 'Sequel100Black75', sans-serif;
    font-size: 22px;
    height: 101%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-index, &-names {
    color: white !important;
    background-color: #1D1D1B !important;
  }
  &-names {
    font-family: 'DharmaGothicM-ExBold', sans-serif;
    font-size: 40px;
    justify-content: start;
    text-transform: uppercase;
  }

  &-firstname {
    color: #BF9C30;
    font-size: 40px !important;
    margin-right: 5px;
    font-family: 'DharmaGothicM-ExBold', sans-serif;
  }
}
.cell-lastName {
  color: #FFFFFF;
  padding-left: 5px;
}

.league-table-player-cell, .cell-padding-names {
  text-align: start;
  width: 393px;
  padding-left: 10px;
}
.cell-padding-names {
  position: relative;
}

.points {
  color: #FFCF40;
  width: 53px;
  font-size: 22px;
  height: 101%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1D1D1B;
  font-family: 'Sequel100Black75', sans-serif;
}

.adc-qualifier {
  width: 80px;
  position: absolute;
  right: 5px;
  &-uk, &-eu, &-oceania {
    display: block;
  }
  &-uk {
    max-width: 90%;
    margin-left: 5px;
  }
  &-eu {
    max-width: 125%;
    transform: translateX(-10px);
  }
  &-oceania {
    max-width: 105%;

  }
}

.league-table-wrapper {
  &-laptop {
    margin: -15% 0px 0% 50%;
  }
}


