.appLoader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid rgba(33, 133, 208, 0.21);
  border-right-color: #2185d0;
  animation: l2 1s infinite linear;
  margin-left: -20;
}
@keyframes l2 {
  to {
    transform: rotate(1turn)
  }
}

