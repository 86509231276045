$dark:#211f20;
$dark-gold:#847113;
.empty-timeline {
    text-align: center;
    color: white;
    font-size: xx-large;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.timeline {
    overflow: auto;
    max-height: 14rem;
    background-color: $dark;
    color: white;
    span {
        font-size: 1rem;
    }
    &__round {
        padding: 0 !important;
        height: 100%;
        .column {
            padding: 0.2rem 0 !important;
        }
        &__teams {
            background-color: white;
            color: black;
            font-weight: bolder;
            font-size: larger;
        }
        &__time {
            background-color: rgba(255, 255, 255, 0.25);
            text-align: center;
            width: 100%;
        }
        &__score {
            text-align: center;
            width: 100%;
        }
        &__icon {
            text-align: center;
            width: 100%;
        }
    }
}