.home-results {
  position: absolute;
  left: 12%;
  top: 30%;
}
.away-results {
  position: absolute;
  right: -5%;
  top: 30%;
}
.results {
  font-family: "Sequel100Black65", sans-serif;
  width: 500px;
  .result-away {
    position: absolute;
    width: 100%;
    background: #333232;
    height: 60px;
    margin-bottom: 20px;
    right: 235px;
  }
  .result-away:nth-child(2) {
    top: 80px;
  }
  .result-away:nth-child(3) {
    top: 160px;
  }
  .result-away:nth-child(4) {
    top: 240px;
  }
  .result-away:nth-child(5) {
    top: 320px;
  }
  .result {
    position: relative;
    width: 50%;
    background: #333232;
    height: 60px;
    margin-bottom: 20px;
    right: 0;

    &__value {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -5px;
      color: white;
      border-radius: 50%;
      font-size: 40px;
      &__win {
        background: #c6a81c;
      }
      &__loss {
        background: lightgrey;
      }
    }
    &__value-left-pos {
      left: -25px;
    }
    &__value-right-pos {
      right: -25px;
    }
  }
}
.player-last-result {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;

  .block-label {
    position: relative;
    width: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Sequel100Black75", sans-serif;
    padding-left: 40px;

    .title-inner {
      display: block;
      position: relative;
      background: black;
      width: fit-content;
      -webkit-clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
      clip-path: polygon(2% 0%, 100% 0%, 97% 100%, 0% 100%);
      margin-left: -9%;
      padding-right: 30px;
      z-index: 1001;
    }

    &__title {
      font-size: 28px;
      align-content: flex-end;
      line-height: 50px;
      letter-spacing: -2px;
      margin-bottom: 0;
      margin-left: 22px;
      z-index: 1001;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__subtitle {
      color: #c6a81c;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: -2px;
      padding: 3px 15px 3px 19px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .subtitle-inner {
      width: max-content;
      white-space: nowrap;
      background: black;
      -webkit-clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
      clip-path: polygon(2% 0%, 100% 0%, 97% 100%, 0% 100%);
      margin-left: -1%;
    }

    .block-label-decor {
      width: 5px;
      background: #c6a81c;
      height: 100%;
      position: absolute;
      right: 12px;
      transform: rotate(8deg);
    }
  }
}


