.pageControls {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
.tableControls {
    display: flex;
    column-gap: 20px;
    justify-content: center;
}
.noContent {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.filterInner {
    margin-right: 6px;
    position: relative;
}



