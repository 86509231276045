.pre-match-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #323b44;
}
.pre-match-participants {
  font-family: 'Sequel100Black65', sans-serif;
  color: #FFCF40;
  display: flex;
  position: absolute;
  top: 30px;
  left: 105px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.pre-match-players {
  margin: 0 20px;
}

.pre-match-failed-request {
  font-family: Sequel100Black65, sans-serif;
  color: #FFCF40;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 40px;
}


.blackBgLayout {
  background-color: black !important;
  width: 100%;
  height: 100vh;
  position: absolute;
}
