.cms__container {
  display: grid;
  grid-template-columns: 250px auto;
  grid-column-gap: 20px;
  padding-right: 20px;
  .button {
    margin: 0 5px !important;
  }

  .cms-content {
    padding-top: 15px;
    padding-bottom: 20px;
    width: 100%;
    .cms-dashboard {
      &__header {
        width: 100%;
        text-align: left;
        padding: 3rem 5rem;
        font-size: 2rem;
        background-color: aliceblue;
      }
    }
  }
}
.ui.pagination.menu .active.item {
  background-color: #21ba45 !important;
  color: #FFFFFF !important;
}
