.player-scoreboard-header {
  width: 485px;
  color: white;
  line-height: 30px;
  $border-width: 1px;
  $out-border-width: $border-width * 2;
  .row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: bold;
    font-size: 30px;


    .col {
      border: $border-width solid white;
      padding: 15px;
      text-align: center;
      height: 100%;
      justify-content: center !important;
    }
  }

  .scores-row {
    font-size: 60px;
    line-height: 40px;

  }

  .row:first-child .col {
    border-top-width: $out-border-width;
  }

  .row:last-child .col {
    border-bottom-width: $out-border-width;
    justify-content: center;
  }

  .row:not(.reverse) {
    .col:first-child {
      border-left-width: $out-border-width;
    }

    .col:last-child {
      border-right-width: $out-border-width;
    }
  }

  .row.reverse {
    .col:first-child {
      border-right-width: $out-border-width;
    }

    .col:last-child {
      border-left-width: $out-border-width;
    }
  }

  .reverse {
    flex-direction: row-reverse !important;
  }

  .active {
    background-color: #f6c143;
    color: black;
  }
}


.player-name {
  justify-content: center;
}

