.league-table-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
}
@media (min-width: 1399px) and (max-width: 1919px) {
  .root__container {
    transform: translateX(-14%);
  }
  .counters-container {
    left: 0;
  }
  .prematch-control {
    left: -10px;
  }
}

@media (max-width: 1685px) {
  .player-card-name__first-name, .player-card-name__last-name {
    font-size: 65px !important;
  }
}
