.schedule {
    &__top-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 20px;
        top: 20px;
        .button {
          margin: 0 5px !important
        }
    }
    &__input-inner{
       display:flex;


    }
    &__filter-btn {
       margin-left: 20px !important;
    }

    &__username-block {
        font-size: 24px;
        padding-right: 30px;
        font-weight: bolder;
    }
    &__container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .schedule-logout {
            font-size: 1.7em !important;
        }
        .sport-select-btn {
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 70px;
            padding: 5px !important;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &__stats-type {
        margin-top: 1rem;
        display: flex;
        .button {
            width: 100%;
            font-size: 1.4rem !important;
        }
    }
    &__sports {
        display: flex;
    }
    &__match-select {
        width: 50%;
        padding: 20px 0;
    }
    &__tournaments-inner{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    &__login {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            margin: 0 5px !important;
        }
    }
}
.cms-btn {
    position: relative;
    width: 85px !important;
    padding-left: 27px !important;
    height: 36px !important;
    &-hidden {
        display: none !important;
    }
}

.schedule-info-block{
    margin-top: 30px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.schedule-games-label {
    margin-bottom: 3px !important;
    &-adc {
        margin-top: 20px !important;
    }
}
.schedule__dropdown {
    //max-height: 200px !important;
    //overflow: auto !important;
}
