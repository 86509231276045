.sidebar-container {
  min-height: calc(100vh - 95px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-icon {
  padding-top: 7px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.animation-container {
  width: 250px;
}

.sidebar-icon-text {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.cms-dashboard__header {
  margin-bottom: 10px;
  color: rgba(34, 36, 38, .5);
  display: flex;
  justify-content: space-between;
  h6 {
    margin: 0;
  }
}