
.fixture {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  color: white;
  font-size: 45px;
  font-family: "DharmaGothicM-ExBold", sans-serif;

  .playerHome {
    text-align: right;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .playerAway {
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .matchResult {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;

    .decor {
      width: 3px;
      height: 45px;
      background: #BF9C30;
      margin-top: -10px;
    }
  }
}

.greyColor {
  color: #808385;
}

.goldColor {
  color: #FFCF40;
}