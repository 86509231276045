.pagerInner {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: 10px;
}

.pagerSelect {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 1000px !important;
}