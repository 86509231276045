$bg-color: black;
.match-stats {
  padding-bottom: 1%;
  height: auto;
  width: 100%;
  background-color: #283747;
  color: invert($color: $bg-color);
  display: flex;

  .ui.grid > .row > .column {
    display: flex !important;
  }

  .panel-column {
    z-index: 1;
    padding-top: 15px;
    margin-left: 30px;

    .logo-container {
      text-align: center;
    }

    .score-panel-inner {
      position: relative;
      background-color: #242422;
      left: -10px;
      bottom: 40px;
      width: 420px;
      min-height: 950px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 20px auto;
      z-index: 1;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        top: -80px;
        left: -5%;
        width: 155px;
        height: 130%;
        background-color: #1D1D1B;
        z-index: -10;
        transform: rotate(8deg);
      }
    }

    .score-panel {
      height: 100vh;
      min-height: 700px;
      padding-top: 70px;

      &-footer {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 2%;
        right: 23%;

        &-img {
          display: block;
          width: 150%;
        }
      }
    }

    .logo {
      height: 163px;
      width: 220px;
    }

    .panel {
      margin-top: 35px;

      &-stats-firstname {
        color: #BF9C30;
      }

      &-stats-names {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'DharmaGothicM-ExBold', sans-serif;
        text-transform: uppercase;
        margin-bottom: 100px;

        &-wrapper {
          padding: 0 35px;
          font-size: 40px;
          line-height: 40px;
          margin-bottom: 15px;

          &-away {
            text-align: end;
          }
        }
      }

      &-stats-item {
        height: 55px;
        width: 100%;
        margin-top: 15px;
        padding: 0 35px;
        background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF1A 50%, #FFFFFF00 100%) 0 0 no-repeat padding-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-card {
          display: block;
          min-height: 55px;
          height: fit-content;
          flex-direction: column;
          margin-top: 15px;
          padding-top: 5px;
          text-align: center;

          &-career {
            display: flex;
            flex-direction: column;

            .panel-stats-value {
              margin: 25px 0 8px 0;
            }
          }
        }
      }

      &-stats-title {
        color: #808385;
        font-family: 'Sequel100Black65', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;

        &-block {
          text-align: center;
          line-height: 12px;
        }
      }

      &-stats-value {
        font-family: 'Sequel100Black75', sans-serif;
        font-size: 22px;
        color: #FFCF40;
        margin-top: 10px;

        &-part {
          font-family: 'Sequel100Black75', sans-serif;
          font-size: 11px !important;
        }

        &-card {
          padding-top: 8px;
        }

      }
    }
  }

  .content-column {
    z-index: 0;

    .content {
      width: 1200px;
      max-width: 100%;
      background-color: #283747;
      padding-bottom: 40px;

      .border-container {
        padding-left: 20px;
        padding-right: 15px;
        padding-top: 15px;

        .game-scores-wrapper {
          width: 100%;
          margin-bottom: 90px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
  }

  .not-found {
    margin: 2rem;
    color: invert($color: $bg-color);
    font-weight: bolder;
    font-size: xx-large;
  }
}

.match-stats .content-column .content {
  max-width: 975px !important;
}

.match-stats-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-color;
  color: white;
}

.counter {
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  background-color: black;
  max-width: 200px;
  width: 100%;
  height: 135px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &__scoreboard {
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-bottom: 2px;
    border-radius: 10px;

    &__value {
      font-size: 50px !important;
      color: $bg-color;
    }
  }

  &__title {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 18px;

    span {
      text-transform: uppercase;
    }
  }
}

.wrapper-score {
  max-width: 82%;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  .panel-stats-value-part {
    font-size: 11px;
  }
}

.scores-table {
  max-width: 173px;
  height: 132.94px !important;
  border: none !important;
  background: transparent !important;
  border-spacing: 4px !important;
  border-collapse: separate !important;
  font-size: 26px !important;
  text-align: center !important;
  margin: 0 40px 34px !important;

  &-bottom {
    max-width: 180px;
  }

  .table-body {
    color: white !important;

    .table-body-row {
      color: black !important;
      background: white !important;
    }
  }
}

.table-cell {
  padding: 25px 5px !important;
  font-family: "BebasKai";
  font-size: 34px;

}

.cell {
  max-width: 100px;
}

.prematch-control {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  justify-content: space-between;

  &__btn {
    width: 52px;
    height: 42px;
    outline: none;
    border: 3px solid black;
    text-transform: uppercase;
    border-radius: 10px;
    background: #C89702;
    font-family: "BebasKai";
    font-size: 23px;
    cursor: pointer;
    transition: .3s;
    margin-bottom: 10px;

    &-stats {
      margin-top: 37px;

      &-img {
        display: block;
        max-width: 100%;
      }
    }
  }

  &__btn-active {
    border: 3px solid white;
  }
}

.player-card {
  position: relative;
  left: 40px;
  bottom: 20px;
  width: 540px;
  height: 100vh;
  min-height: 950px;
  background-color: #242422;
  margin: 0 auto;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 20px;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    top: -80px;
    left: -5%;
    width: 155px;
    height: 130%;
    background-color: #1D1D1B;
    z-index: -10;
    transform: rotate(8deg);
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 50px;
  }

  &__logo-img {
    height: 163px;
    width: 300px;
  }

  .player-card-name {
    font-weight: bold;
    text-align: center;
    margin-top: 225px;
    margin-bottom: 50px;


    &__first-name {
      font-family: "DharmaGothicM-ExBold", sans-serif;
      font-size: 80px;
      margin: 0;
      text-transform: uppercase;
      color: #BF9C30;
      line-height: 1em;

    }

    &__last-name {
      font-family: "DharmaGothicM-ExBold", sans-serif;
      font-size: 80px;
      margin: 0;
      text-transform: uppercase;
      line-height: 0.9em;
    }
  }

  .player-card-info {
    margin-top: 85px;

    .card-parameter {
      font-family: "BebasPro";
      display: flex;
      margin-bottom: 25px;

      .player-card-info__param:first-child {
        text-align: right;
      }

      .player-card-info__param:last-child {
        text-align: right;
      }

      .player-card-info__param {
        font-size: 22px;
        letter-spacing: 0px;
        color: #C6A81C;
        text-transform: uppercase;
        white-space: nowrap;
        min-width: 115px;
      }

      .player-card-info__value {
        font-size: 22px;
        letter-spacing: 0px;
        color: white;
        text-transform: uppercase;
        white-space: pre-wrap;
        margin-left: 30px;
      }
    }
  }
}

.ui, .grid {
  margin-right: 0 !important;
}

.right-content-column {
  display: flex !important;
  flex-direction: column;
  align-items: baseline;
  position: sticky;
}

.counters-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 1%;
  left: 100px;
  overflow: hidden;
}

.counter-refresh-btn {
  position: absolute;
  display: block;
  border: none;
  top: -40%;
  width: 235px;
  height: 40px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
  background-color: #f6c34e;
  text-transform: uppercase;
  font-family: "Bison";

}

.scores-header {
  height: 23px;
  font-size: 12px;
  background-color: black;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &-counter {
    padding-top: 5px;
    height: 23px;
    background-color: #bf9c30;
    font-size: 12px;
    font-family: 'Sequel100Black65', sans-serif;
  }

  span {
    text-transform: lowercase;
    font-size: 7px !important;
  }

  .item-180s {
    transform: translateX(10px);
  }
}

.score-table {
  height: 165px;
  text-align: center;
  line-height: 14px;
  margin: 0 20px 30px 20px;
  font-family: 'Sequel100Black65', sans-serif;
  text-transform: uppercase;

  &-checkouts {
    width: 149px;
  }

  &-average {
    width: 184px;
  }

  &-hi-checkouts {
    width: 177px;
  }

  &-average-180 {
    width: 301px;
  }

  &-100-140-180 {
    width: 306px;
  }

  &-counter {
    width: 186px;
    height: 108px;
  }
}

.table-body-inner {
  background-color: white;
  border-radius: 3px;
  height: 63px !important;
  margin-top: 5px;
  color: black;
  font-size: 37px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &-counter {
    height: 80px;
    font-family: 'Sequel100Black75', sans-serif;
    font-size: 50px;
    font-weight: bold;
  }

  &-item {
    width: 50%;

    &-average-180 {
      padding-left: 10px;
    }

    &-line {
      position: relative;

      &:before {
        content: '';
        width: 2px;
        height: 44px;
        position: absolute;
        right: 106px;
        top: -15px;
        display: block;
        background-color: #e6e6e7;
        border-radius: 2px;
      }
    }
  }

  &-average-checkouts {
    justify-content: center;
    align-items: baseline;
    padding-top: 25px;
  }
}

.score-table-100-140-180 {
  .table-body-inner-item-line:before {
    right: inherit;
  }
}

.table-body-inner-item-line-average-180 {
  padding-left: 41px;
}

