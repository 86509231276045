.table-header-cell {
  cursor: default !important;
}

.players-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 1250px;

  &-avatar {
    cursor: pointer;
  }
}

.players-table-controls {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  padding-right: 5px;
}


