.tours-form {
  width: 370px;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
}
.temporary-field {
  position: relative;
}
.temporary-message {
  position: absolute;
  font-size: 14px;
  color: rgba(255, 0, 0, 0.4);
  left: 25%;
  top: 3px;
  z-index: 1;
}