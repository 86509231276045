@media (max-width: 1338px) {
  .post-match-wrapper {
    .static-statistic {
      &-wrapepr {
        padding: 0 10px;
      }
      &-names {
        font-size: 50px;
      }
      &-item {
        height: 50px;
        margin-top: 10px;
        &-title {
          font-size: 20px;
        }
      }
      &-total-points-wrapper {
        margin: 0 20px;
      }
    }
  }
}
@media (min-width: 1339px) and (max-width: 1441px) {
  .post-match-wrapper {
    .static-statistic {
      &-names {
        font-size: 60px;
      }

      &-item {
        height: 60px;
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: 1442px) and (max-width: 1679px) {
  .post-match-wrapper {
    .static-statistic {
      &-names {
        font-size: 60px;
      }

      &-item {
        height: 60px;
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: 1650px) and (max-width: 1919px) {
  .post-match-wrapper {
    .static-statistic {
      &-names {
        font-size: 80px;
      }
      &-item {
        height: 80px;
      }
    }
  }
}
@media (max-height: 970px) {
  .static-statistic-logo  {
    position: sticky;
    transform: initial;
    margin-top: 10%;
  }
  .static-statistic {
    overflow: scroll !important;
  }

}
