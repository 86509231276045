@font-face {
  font-family: "BebasKai";
  src: local("BebasKai"),
  url("./fonts/BebasKai.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Bison";
  src: local("Bison"),
  url("./fonts/Bison-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BebasPro";
  src: local("BebasPro"),
  url("./fonts/BebasPro.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BebasExtraBold";
  src: local("BebasExtraBold"),
  url("./fonts/BebasExtraBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Sequel100Black75';
  src: url('./fonts/Sequel100Black75.woff2') format('woff2'),
  url('./fonts/Sequel100Black75.woff') format('woff');
}

@font-face {
  font-family: 'Sequel100Black65';
  src: url('./fonts/Sequel100Black65.woff2') format('woff2'),
  url('./fonts/Sequel100Black65.woff') format('woff');
}

@font-face {
  font-family: 'DharmaGothicM-ExBold';
  src: url('fonts/DharmaGothicM-ExBold.woff2') format('woff2'),
  url('fonts/DharmaGothicM-ExBold.woff') format('woff');
}
.rc-time-picker-panel-input {
  width: 200px !important;
}
.rc-time-picker-panel-combobox {
  display: flex;
  justify-content: space-around;
}
.rc-time-picker-panel-select {
  width: 50%;
}