.denied-page {
  &__container {
    display: flex;
    padding-top: 30px;
    background-color: #171b21;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 100vh;
    font-size: 24px;
  }
  &__message {
    font-size: 30px;
  }
  &__backward-btn {
    margin-top: 40px;
    border: 1px solid white;
    padding: 7px 15px;
    border-radius: 25px;
    &:hover {
      cursor: pointer;
      background-color: #363d46;
    }
  }
}