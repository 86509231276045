.back-arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 25px;
  left: 25px;
  outline: none;
  border: 1px solid white;
  border-radius: 5px;
  background: transparent;
  color: #FFFFFF;
  .back-arrow__icon {
    font-size: 12px;
    margin-top: -6px;
  }
  &__text {
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
    color: #FFFFFF;
  }
}

.stats-inner {
  display: flex;
  justify-content: center;
}

.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  color: #FFFFFF;

  &__datepicker {
    display: flex;
    align-items: center;
  }

  &__flatpickr {
    height: 40px;
    border-radius: 5px;
    padding-left: 10px;
  }

  &__download-btn {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 30px;

  }

  &__datepicker:nth-child(2) {
    margin-left: 10px;
  }

  .statistics-inputs {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
  }
}
