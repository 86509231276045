.scoreboard {
  width: 1000px !important;
  background: inherit !important;
  height: fit-content;

  &_header {
    height: 140px;
  }

  &_body {
    max-height: calc(100vh - 170px);
    overflow: auto;
    scroll-behavior: auto;


    #left_score .row {
      padding-right: 125px;

      .score-value {
        margin-left: 120px;
      }
    }

    #right_score .score-value {
      padding-left: 40px;
      margin-left: 80px;
    }
  }
}
