$dark:#211f20;
$dark-gold:#847113;
.section {
    background-color: $dark;
    &__title {
        font-size: x-small;
        padding-left: 0.5em;
        text-transform: uppercase;
        background-color: white;
        color: black;
    }
    &__content {
        span {
            font-size: small;
        }
        padding: 0.2em;
        background-color: $dark;
        color: white;
        &__title {
            margin-left: 0.5em;
        }
        &__value {
            color: white;
            float: right;
            margin-right: 0.5em;
        }
    }
}

.highlighted {
    background-color: white;
    border: 0.1em solid white;
    border-radius: 1em;
    color: $dark;
}

.centered-h {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.centered-v {
    display: flex !important;
    align-items: center !important;
}