@media (min-width: 1365px) and (max-width: 1441px) {
  .player-last-result {
    .title-inner {
      margin-left: 5% !important;
    }
    .subtitle-inner {
      margin-left: 8% !important;
    }
  }
  .results {
    width: 400px !important;
  }
  .away-results {
    right: -9%;
  }

}

@media (min-width: 1442px) and (max-width: 1534px) {
  .player-last-result {
    .block-label {
      .title-inner {
        margin-left: 17%;
      }

      .subtitle-inner {
        margin-left: 23%;
      }
    }
  }
  .results {
    width: 450px;
  }
  .away-results {
    right: -12%;
  }
  .home-results {
    left: 13%;
  }
}

@media (min-width: 1535px) and (max-width: 1678px) {
  .block-label {
    .title-inner {
      margin-left: 10%;
    }

    .subtitle-inner {
      margin-left: 5%;
    }
  }
  .results {
    width: 450px;
  }
  .away-results {
    right: -10%;
  }
}

@media (min-width: 1679px) and (max-width: 1681px) {
  .away-results {
    right: -5%;
  }
}

@media (min-width: 1910px) and (max-width: 1921px) {
  .results {
    width: 600px;
  }
  .away-results {
    top: 36%;
  }
  .home-results {
    top: 36%;
  }
}

@media (min-width: 1970px) and (max-width: 2561px) {
  .results {
    width: 550px;

    .result__value {
      width: 85px;
      height: 85px;
    }
  }
  .home-results {
    left: 14%;
    top: 40%;
  }
  .away-results {
    right: 2%;
    top: 40%;
  }
}

