.match-page {
    &__container {
        height: 100vh;
    }
    &__start-btn {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
    &__back-arrow {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 30px;
        left: 30px;
    }
    &__back-arrow-text {
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
    }
    &__sports-info {
        width: 100%;
        background-color: #1a1f25;
        height: inherit;
        .game-title {
            text-align: center;
            color: white;
            font-weight: bold;
            font-size: 1.3rem;
        }
    }
    &__title {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
        .game-title {
            font-size: 1.3rem;
            margin-right: 10px;
        }
        .logout-btn {
            z-index: 1;
            cursor: pointer;
            padding: 0px 5px;
            border-radius: 20px;
            border: 1px solid white;
            &:hover {
                background-color: lightslategrey;
            }
        }
    }
}

.readonly {
    position: absolute;
    background-color: red;
    color: white;
    left: 5px;
    top: 5px;
    border-radius: 5px;
    padding: 5px 10px;
}
.match-loader {
    background-color: #1D1D1B;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
@media(max-width:769px) {
    .match-page{
        &__container {
            // display: flex;
            // flex-direction: column;
        }
    }
}
