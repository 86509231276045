.broadcaster-logos {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 600;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  -webkit-box-shadow: 7px 0 20px 14px rgb(0 0 0);
  box-shadow: 7px 0 20px 14px rgb(0 0 0);
}

.broadcaster-logo-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 50%;


  &__left-side {
    position: absolute;
    left: 2%;
    bottom: 15px;
    padding-left: 20px;
    img {
      width: 70%;
    }
  }
}

.broadcaster-logo-igm {
  width: 100%;
  height: 100%;
}

.red-dragon-logo-inner {
  position: absolute;
  right: -3%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-right: 20px;

  .red-dragon-logo {
    display: block;
    width: 80%;
  }
}
