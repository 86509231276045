.actions-container {
  width: 100%;
  height: calc(35vh - 20px);
  
  .segment {
    background-color: #454d55!important;
    height: 100%;
    display: flex;
  }

  &__toggle {
    background-color: #1a1f25;
    border-radius: 5px;
    color: white;
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
  &__sidebar {
    width: 95%;
    float: right;
    height: 100%;
    overflow-x: scroll;
    overflow-y: auto;

    & .segment {
      background-color: #1a1f25!important;
    }
  }
}