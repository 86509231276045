.overlay {
  background: rgba(54, 54, 54, 0.29);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 400px;
  height: 200px;
  background: #2B2B2B;
  color: #FFFFFF;
  position: relative;
  font-size: 22px;
}

.content {
  padding: 20px;
}

.title {
  font-size: 24px;
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
}

.btnWrapper {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.controlBtn {
  width: 50% !important;
  border-radius: 0 !important;
}
