.codeBtn {
  display: flex;
  justify-content: center;
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: #4183c4;;
  }
}
.goToLoginBtn {
  text-decoration: underline;
}