.overall-label {
  position: relative;
  width: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 15%;

  .overall-label-decor {
    width: 5px;
    background: #c6a81c;
    height: 100%;
    position: absolute;
    right: 12px;
    transform: rotate(8deg);
  }
}

.players-average {
  position: fixed;
  color: white;
  font-family: 'Sequel100Black65', sans-serif;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 50px;

  .home-average-value-inner {
    position: fixed;
    right: -35px;
    top: 0;
    z-index: 1;
    overflow: hidden;
    height: 105%;
  }

  .away-average-value-inner {
    position: fixed;
    right: -20px;
    top: 0;
    z-index: 1;
    height: 105%;
    overflow: hidden;
  }

  .average-score {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 800px;

    &__value {
      font-size: 90px;
      text-transform: uppercase;
      font-family: "Sequel100Black65", sans-serif;
      margin: 0;
      line-height: 190px;
      letter-spacing: -5px;
      z-index: 0;
      text-align: justify;
    }
    &__percent {
      font-size: 5rem;
    }

    &__label {
      display: inline-block;
      -webkit-clip-path: polygon(5% 0%, 96% 0%, 90% 100%, 0% 100%);
      padding: 0 27px 0 12px;
      text-align: center;
      background: black;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -3px;
      margin-bottom: 0;
      margin-left: 21px;
      text-transform: uppercase;
      white-space: nowrap;
      width: max-content;
    }

    .home-average-label-inner {
      display: block;
      position: relative;
      width: 235px;
      margin-left: 20%;
      margin-top: -20px;
      z-index: 1001;
    }

    .average-type-inner {
      width: max-content;
      white-space: nowrap;
      margin-left: 30%;
    }

    &__type {
      display: inline-block;
      background: black;
      -webkit-clip-path: polygon(2% 0%, 100% 0%, 97% 100%, 0% 100%);
      text-align: center;
      margin-left: 20px;
      color: #c6a81c;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: -2px;
      padding: 3px 15px 3px 19px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.average-score__translate-left {
  transform: translate(-20%);
}

.average-score__translate-right {
  transform: translate(20%);
}

.average-vs {
  font-size: 10rem;
  font-family: "BebasExtraBold", sans-serif;
  margin: 0;
  text-align: center;
  line-height: 100px;
  padding-bottom: 30px;
}