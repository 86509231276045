.form {
  width: 600px;
  background: #FFFFFF;
  padding: 15px;
  min-height: 400px;
  max-height: 90vh;
  border-radius: 15px;
  overflow: auto;
}

.inputInner {
  padding-top: 8px;
  width: 100%;
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
}
.formField {
  width: 50%;
}
