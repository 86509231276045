.cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
}
.controls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}