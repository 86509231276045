@media (max-width: 1367px) {
  .player-photo {
    &__right-position {
      left: 65%;
    }
  }
}

@media (min-width: 2407px) {
  .player-photo {
    &__right-position {
      right: -19%;
      top: 245px;
    }

    &__left-position {
      left: -3%;
      top: 270px;
    }
  }
}
