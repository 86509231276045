@media (min-width: 1365px) and (max-width: 1441px) {
  .players-average {
    .average-score__value {
      font-size: 70px;
    }

    .home-average-value-inner {
      top: -100% !important;
      left: -3% !important;
    }

    .away-average-value-inner {
      top: 90% !important;
      left: 40% !important;
    }
  }
}

@media (min-width: 1442px) and (max-width: 1678px) {
  .players-average {
    .title-inner {
      margin-left: 5%;
    }
    .subtitle-inner {

    }
    .average-score__value {
      font-size: 80px !important;
    }

    .home-average-value-inner {
      top: -83%;
      left: -15% !important;
    }

    .away-average-value-inner {
      top: 80%;
      left: 35% !important;
    }
  }
}
@media (min-width: 1679px) and (max-width: 1799px) {
  .players-average {
    .average-score__value {
      font-size: 95px;
    }

    .home-average-value-inner {
      top: -100%;
      left: -10% !important;
    }

    .away-average-value-inner {
      top: 103%;
      left: 30% !important;
    }
  }
}

@media (min-width: 1800px) and (max-width: 2200px) {
  .players-average {
    .average-score__value {
      font-size: 100px;
    }

    .home-average-value-inner {
      top: -150px;
      left: -110px;
    }

    .away-average-value-inner {
      top: 160px;
      left: 150px;
    }
  }
}

@media (min-width: 2201px) and (max-width: 2406px) {
  .players-average {
    .average-score__value {
      font-size: 120px;
    }
    .home-average-value-inner {
      top: -150px;
      left: -160px;
    }

    .away-average-value-inner {
      top: 160px;
      left: 260px;
    }
  }
}

@media (min-width: 2407px) {
  .players-average {
    .average-score__value {
      font-size: 130px !important;
    }

    .away-average-value-inner {
      top: 160px;
      right: -70%;
      height: 120% !important;
      width: 140% !important;
    }

    .home-average-value-inner {
      top: -160px;
      left: -60%;
      height: 120% !important;
      width: 170% !important;

    }
  }
}
