$gameScoresFontSize: 26px;
$gameScoresBodyCornerRadius: 10px;
.match-stats-shoot-out {
  .wrapper {
    transform: none !important;
  }
  .root__container {
    transform: none !important;
  }
  .root__container .game-scores__container {
    padding: 0 !important;
  }
}


.root__container {
  .game-scores {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    &__container {
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .long.arrow.alternate.right {
        height: 38px;
        width: 38px;
        display: flex;
        align-items: center;

        &:before {
          color: #f6c042;
          font-size: 45px;
        }
      }

      .wrapper {
        width: 624px !important;
        height: 218px !important;
        position: relative;
      }

      .shootout-game-scores-table {
        border: 0 !important;
        background: #000;
        border-spacing: 2px;
        border-collapse: separate;
        padding: 0px;
        border-radius: 13px;
        font-size: 12px !important;

        .shootout-value-column {
          width: 75px;
          text-align: center;
          text-transform: uppercase !important;
          padding: 0 !important;
          font-size: 28px;
        }

        .value-column {
          width: 75px;
          text-align: center;
          text-transform: uppercase !important;

          div {
            font-size: $gameScoresFontSize;
          }
        }

        .game-scores-header {
          tr {
            background: inherit !important;
            line-height: 6px;
            font-size: 11px;
          }

          th {
            background: inherit !important;
            color: white !important;
            font-weight: normal !important;

            div {
              font-size: $gameScoresFontSize;
            }
          }

          color: white !important;
        }

        .shootout-game-scores-body :first-child .score {
          background: linear-gradient(rgb(249, 212, 128), rgb(247, 197, 81));
        }

        .shootout-game-scores-body > :first-child > :last-child {
          border-top-right-radius: $gameScoresBodyCornerRadius;
        }

        .shootout-game-scores-body > :first-child > :first-child {
          border-top-left-radius: $gameScoresBodyCornerRadius;
        }

        .shootout-game-scores-body > :nth-child(3) > :first-child {
          border-bottom-left-radius: $gameScoresBodyCornerRadius;
        }

        .shootout-game-scores-body > :nth-child(3) > :last-child {
          border-bottom-right-radius: $gameScoresBodyCornerRadius;
        }

        .game-scores-row {
          .current-thrower {
            height: 19px;
            width: 19px;
            background-size: contain;
            margin-left: 5px;
            background-repeat: no-repeat;
            border: none;
          }

          &__player {
            color: black;
            background: linear-gradient(rgb(242, 242, 242), rgb(228, 228, 228));
            padding: 0px 5px;

            .player {
              &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }

              &__info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }

              &__name {
                font-family: 'DharmaGothicM-ExBold', sans-serif !important;
                font-size: 64px !important;
                margin-left: 10px;
              }

              &__throws {
                border-radius: 50%;
                width: 33px;
                height: 33px;
                padding-top: 4px;
                background: #fff;
                border: 2px solid black;
                text-align: center;
                font-size: $gameScoresFontSize;
              }
            }
          }

          .score {
            text-align: center;
            color: black;
            background: linear-gradient(rgb(246, 195, 75), rgb(247, 197, 81));

            div {
              font-size: 34px;
            }
          }
        }
      }
    }
  }
}

.standard {
  background: linear-gradient(rgb(246, 195, 75), rgb(247, 197, 81));
}

.tables-container {
  margin-top: 5rem;
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  height: 100% !important;
  justify-content: center;

  table {
    margin: 0 3rem !important;
    width: max-content !important;

    td,
    th {
      text-align: center !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 1.2rem;
      overflow: hidden;
    }

    thead {
      th {
        background-color: black !important;
        color: white !important;
      }
    }

    tbody {
      tr {
        td {
          border: 2px solid black;
          border-collapse: collapse;
        }

        .score {
          text-align: center;
          color: black;
          background-color: #f6c042;
        }
      }
    }
  }
}

.animation-180-away {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgb(197, 29, 29);
  top: 100px;
  width: 0px;
  height: 63px;
  font-size: 35px;
  transition: 0.4s;
  overflow: hidden;
}

.animation-180-home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgb(197, 29, 29);
  right: 0px;
  top: 30px;
  width: 0px;
  height: 50px;
  font-size: 35px;
  transition: 0.4s;
  overflow: hidden;
}

.animation-180-away-active {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 96px !important;
  background: rgb(197, 29, 29);
  width: 78px;
  height: 63px;
  font-size: 35px;
  transition: linear 0.2s;
}

.animation-180-home-active {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 30px;
  background: rgb(197, 29, 29);
  width: 78px;
  height: 50px;
  font-size: 35px;
  transition: linear 0.2s;
}

.shootout-name-cell {
  padding: 0 0 0 4px !important;
  height: 32px;
  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
  font-size: 24px;

}

@media only screen and (max-device-width: 1024px) and (-moz-touch-enabled: 1) {
  /* tablets in landscape mode and smaller/older monitors */
  .root__container {
    height: 100%;

    .game-scores {
      height: 100%;

      &__container {
        height: 100%;

        .wrapper {
          width: 100% !important;
          height: 100%;

          .game-scores-table {
            height: 100%;
          }

          .score {
            font-size: 4.7em;
          }
        }

        .player {
          &__throws {
            width: 70px !important;
            height: 70px !important;
            padding-top: 5px !important;
            border: 3px solid black !important;
            font-size: 2.4em !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &__name {
            margin-left: 15px !important;
          }
        }

        .current-thrower {
          height: 64px !important;
          width: 64px !important;
        }
      }

      .value-column {
        width: 200px;
        font-size: 3em;
      }

      .game-scores-header {
        tr {
          height: 200px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) and (-moz-touch-enabled: 1) {
  //for iPad PRO
  .root__container {
    height: 100%;

    .game-scores {
      height: 100%;

      &__container {
        height: 100%;

        .wrapper {
          width: 100% !important;
          height: 100%;

          .game-scores-table {
            height: 100%;
          }

          .score {
            font-size: 5em;
          }

          .icon-cell {
            img {
              height: 80px !important;
            }
          }
        }

        .player {
          &__throws {
            width: 70px !important;
            height: 70px !important;
            padding-top: 5px !important;
            border: 3px solid black !important;
            font-size: 3em !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &__name {
            margin-left: 30px !important;
          }
        }

        .current-thrower {
          height: 80px !important;
          width: 80px !important;
        }
      }

      .value-column {
        width: 200px;
        font-size: 4em;
      }

      .game-scores-header {
        tr {
          height: 200px;
        }
      }
    }
  }
}

@media (min-width: 1599px) and (max-width: 1919px) {
  .match-stats-shoot-out {
    .wrapper {
      transform: none !important;
    }
    .root__container {
      left: 0 !important;
    }
  }
}
