$dark-gold:#847113;
.region {
    &__title {
        background-color: $dark-gold;
        color: white;
    }
}

.tournament {
    &__title {
        background-color: white;
        color: $dark-gold;
    }
}