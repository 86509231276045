.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0,.8);
  display: flex;
  justify-content: center;
  align-items:center;
  z-index: 100;
}

.players-form {
  max-width: 900px;
  background-color: white;
  padding: 15px 30px 30px 30px;
  border-radius: 10px;
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 20px;
    .display-name {
      width: 66%;
    }
    .abbr {
      width: 32%;
    }
    .date-of-birth {
      width: 273px;
    }
    .throw-hand {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
    }
    .dart-used {
      width: 273px;
      .ui.input {
        width: 273px !important;
      }
    }
    .career-best {
      width: 66%;
    }
    .player-adc-qualifier {
      width: 32%;
    }
  }
  .ui.checkbox input[type=checkbox] {
    z-index: 10 !important;
    opacity: 1 !important;
  }
  &-tooltip-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2px;
  }
  &-tooltip-icon {
    display: block;
    width: 25px;
  }
}
.throw-input-inner {
  display: flex;
  column-gap: 42px;
  margin-top: 5px;

}
.radio-input-inner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3px;
  max-width: 50%;
  .field {
    display: flex;
    justify-content: center;
  }
}

.players-form-label {
  margin-bottom: 5px !important;
}
.throw-hand-label {
  max-width: 100px;
  margin-bottom: 0;
}

.player-form-input-radio {
  transform: translate(2px, -2px);
}.player-form-checkbox {
  transform: translate(5px, 4px);
}
.tooltip:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  bottom: 45px;
  left: 0;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.tooltip span {
  background: none repeat scroll 0 0 #222;
  border-radius: 8px;
  color: #F0B015;
  font-family: 'Helvetica', sans-serif;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  width: 240px;
  bottom: 35em;
  z-index: 50;
  left: 0;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  text-transform: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-in-out;
}

.tooltip span:after {
  border-color: #222 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}

.submit-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
}
