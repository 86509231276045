@import url("https://fonts.googleapis.com/css?family=Montserrat|Alegreya+Sans+SC|Julius+Sans+One|M+PLUS+Rounded+1c&display=swap");
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .error {
    color: #ed1d2c !important;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 12px;
    position: absolute;
    top: 80%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}

.login-form {
  min-width: 770px;
  min-height: 442px;
  position: absolute;
  width: 40vw;
  height: 50vh;
  background: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-form .left {
  display: flex;
  width: 50%;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray !important;
    opacity: 1;
    /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: gray !important;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: gray !important;
  }
}

.login-form .left .login-btn {
  text-align: center;
  margin-top: 70px;
  background: #323b44;
  width: 80%;
  border: 0;
  color: white;
  border-radius: 20px;
  padding: 10px 0 10px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
}

.login-form .left .login-btn:hover {
  background-color: white;
  color: #323b44;
  border: 1px solid #323b44;
  cursor: pointer;
  padding: 9px 0 9px 0;
}

.login-form .left .login-btn:active {
  transform: translateY(2px);
  transition-duration: 200ms;
}

.login-form .left .reset-pwd-btn {
  text-align: center;
  margin-top: 10px;
  background: white;
  width: 50%;
  border: 0;
  color: #323b44;
  border-radius: 20px;
  // padding: 10px 0 10px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  height: 30px;
  border: 1px solid #323b44;
}

.login-form .left .reset-pwd-btn:hover {
  background-color: #323b44;
  color: white;
  border: 1px solid #323b44;
  cursor: pointer;
  // padding: 9px 0 9px 0;
}

.login-form .left .reset-pwd-btn:active {
  transform: translateY(2px);
  transition-duration: 200ms;
}

.login-form .left .inputs {
  margin-top: 40px;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.login-form .left .inputs .input {
  position: relative;
  text-align: center;
  background: white;
  border: 1px solid gainsboro;
  padding: 8px;
  display: inline-block;
  border-radius: 9999em;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 10px;
  margin-bottom: 15px;
  color: grey;
  outline: none;
}

.login-form .left .inputs .login {
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}

.login-form .right .logo {
  position: relative;
  width: 210px;
  height: 95px;
  margin-top: 15px;
  .logo-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.login-form .right {
  display: flex;
  width: 50%;
  flex-direction: column;
  color: white;
  background: #333945;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.login-form .right .logo-label {
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 100px;
  color: white;
  font-style: italic;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.login-form .right .frase-label {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 70px;
}

@media screen and (max-width: 800px) {
  .login-form {
    min-width: 385px;
  }
  .login-form .right {
    display: none;
  }
  .login-form .left {
    width: 100%;
  }
}

.version {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: red;
}
.password-link {
  padding-top: 15px;
}
