.loggingHeader {
  height: 120px;
  background: #21ba45;
  color: #FFFFFF;
  font-size: 32px;
  margin-bottom: 20px;
}
.headerText {
  font-family: sans-serif;
  padding-top: 50px;
  text-decoration: underline;
}
.container {
  max-width: 1500px;
  margin: 0 auto;
}