.form-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.competition-form {
  width: 400px;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
}
.competition-label {
  margin-bottom: 10px !important;
}

.competition-adc-qualifier {
  display: flex;
  align-items: center;
}

.competition-checkbox {
  padding: 0 0 10px 10px;
}
