.photo-uploader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 31, 32, 0.63);
  display: flex;
  justify-content: center;
  align-items: center;

  &__show-full-size {
    width: 470px;
    height: 720px;
    position: absolute;
    background: white;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-close {
      position: absolute;
      z-index: 10;
      right: 5px;
      top: 10px;
      width: 32px;
      height: 32px;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: red;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
    img {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__btn_wrapper {
    display: flex;
  }

  &__image-wrapper {
    position: relative;
    width: 400px;
    border-radius: 15px;
    background: white;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;


    .close-icon {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .upload-error-block {
      display: block;
      color: #E20613;
      margin-top: 10px;

    }
  }

  .image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 400px;

    &__title {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-family: "BebasPro", sans-serif;
    }

    .downloadable-image-inner {
      position: relative;
      width: 70%;
      height: 100%;
      border: 1px solid silver;
      padding-bottom: 20px;
      margin-top: 20px;
      margin-bottom: 30px;

      .downloadable-image {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 95%;
        object-fit: contain;
      }
    }
  }

  .photo-widget-btn {
    max-width: 150px;
  }
}
