.form {
  background: #FFFFFF;
  padding: 15px;
  width: 400px;
  border-radius: 15px;
  max-height: 700px;
  overflow: auto;
}
.inputInner {
  padding-top: 8px;
  width: 100%;
}
.selectLabel {
  margin-bottom: 8px !important;
}