.statsPage {
  background: #2B2B2B;
  height: 100vh;
}

.playerStatsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  max-width: 50%;
  margin: 0 auto;
}

.gameStatsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  max-width: 70%;
  margin: 0 auto;
}

.playersSelect {
  border-radius: 5px;
  width: 220px !important;
}
.statsTextInfo {
  white-space: nowrap;
  font-weight: bold;
  color: #FFFFFF;
}
.numberInput {
  border-radius: 5px;
}

.pageTitle {
  text-align: center;
  color: #FFFFFF;
  font-size: 40px;
  padding-top: 20px;
}
