.table {
  width: 265px;
  display: flex;
  flex-direction: column;
  .header {
    height: 23px;
    color: #FFFFFF;
    background: #BF9C30;
    font-size: 12px;
    font-family: "Sequel100Black75", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .valueCell {
    height: 63px;
    background: #FFFFFF;
    color: #1D1D1B;
    font-size: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Sequel100Black75", sans-serif;
  }
}