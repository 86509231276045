
.root__container {
  position: sticky;
  left: 0;

  .game-scores {
    &-right-score {
      margin-left: 59px;
    }

    &-body-scores-wrapper {
      display: flex;
    }

    &-body {
      margin-top: 5px;
    }

    &-footer {
      height: 44px !important;
      border-radius: 2px;
      background-color: #171714;
    }

    &-footer-social {
      background-color: #171714;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;

        img {
          width: 100%;
        }
      }

      &-fb {
        transform: translateX(-3px);
        margin-right: 30px;
      }

      &-inner {
        font-family: 'Sequel100Black65', sans-serif;
        font-size: 16px;
        text-transform: initial;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-footer-available {
      position: relative;
      top: -30px;
      height: 41px;

      &-img {
        width: 100%;
        height: 41px;
      }
    }

    &__container {
      padding-top: 15px;
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .long.arrow.alternate.right {
        height: 38px;
        width: 38px;
        display: flex;
        align-items: center;

        &:before {
          color: #f6c042;
          font-size: 45px;
        }
      }

      .wrapper {
        position: relative;
      }

      .game-scores-table {
        background: transparent;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        .game-scores-header {
          font-family: 'Sequel100Black65', sans-serif;
          font-size: 13px;
          background-color: black;
          height: 23px;
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1px 44px 0 23px;
          border-radius: 2px;

          &-right-wrapper {
            display: flex;
          }

          .name-cell-widget-name {
            margin-left: 30px;
          }
        }


        .game-scores-row {
          height: 63px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          margin-bottom: 5px;
          padding-left: 22px;
          border-radius: 2px;

          .current-thrower {
            height: 19px;
            width: 19px;
            background-size: contain;
            margin-left: 5px;
            background-repeat: no-repeat;
            border: none;
            font-size: 20px;
          }

          &__player {
            color: black;

            .player {
              &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }

              &__info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }

              &__name {
                font-family: 'DharmaGothicM-ExBold', sans-serif;
                font-size: 39px;
                letter-spacing: 0.5px;
                margin-left: 10px;
                padding-top: 2px;
                line-height: 2.3rem;
              }

              &__throws {
                font-family: 'Sequel100Black75', sans-serif;
                font-size: 20px;
                color: #1D1D1B;
                border-radius: 3px;
                width: 43px;
                height: 31px;
                padding-top: 6px;
                text-align: center;
                margin-right: 10px;
              }
            }
          }

          .score {
            font-family: 'Sequel100Black75', sans-serif;
            font-size: 33px;
            color: black;
            height: 35px;
            display: flex;
            justify-content: center;
            padding-top: 23px;

            &-legs {
              width: 75px;
              height: 63px;
            }

            &-value {
              margin-left: 5px;
              width: 145px;
              height: 63px;;
              padding-top: 23px;
            }
          }
        }
      }
    }
  }
}

.wadsColor {
  background: #00aded;
}

.adcColor {
  background: #e64f1e;
}

.standard {
  background: transparent linear-gradient(173deg, #FFCF40 0%, #E7BB3A 59%, #FFCF40 100%) 0 0
}

.defaultColor {
  background: #B2B2B2;
}

.thrower-arrow {
  font-size: 35px;
}


.animation-180-away {
  font-family: "Sequel100Black75", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgb(197, 29, 29);
  right: 0 !important;
  bottom: 120px !important;
  width: 0;
  height: 63px !important;
  font-size: 33px;
  transition: 0.4s;
  overflow: hidden;
}

.animation-180-home {
  font-family: "Sequel100Black75", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgb(197, 29, 29);
  right: 0 !important;
  top: 28px !important;
  width: 0;
  height: 63px !important;
  font-size: 33px;
  transition: 0.4s;
  overflow: hidden;
}

.animation-180-away-active {
  font-family: "Sequel100Black75", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0 !important;
  top: 96px !important;
  background: rgb(197, 29, 29);
  width: 144px !important;
  height: 63px !important;
  font-size: 33px;
  transition: linear 0.2s;
}

.animation-180-home-active {
  font-family: "Sequel100Black75", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0 !important;
  top: 28px !important;
  background: rgb(197, 29, 29);
  width: 144px !important;
  height: 63px !important;
  font-size: 33px;
  transition: linear 0.2s;
}

@media only screen and (max-device-width: 1024px) and (-moz-touch-enabled: 1) {
  /* tablets in landscape mode and smaller/older monitors */
  .root__container {
    height: 100%;

    .game-scores {
      height: 100%;

      &__container {
        height: 100%;

        .wrapper {
          width: 100% !important;
          height: 100%;

          .game-scores-table {
            height: 100%;
          }

          .score {
            font-size: 4.7em;
          }
        }

        .player {
          &__throws {
            width: 70px !important;
            height: 70px !important;
            padding-top: 5px !important;
            border: 3px solid black !important;
            font-size: 2.4em !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &__name {
            margin-left: 15px !important;
          }
        }

        .current-thrower {
          height: 64px !important;
          width: 64px !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) and (-moz-touch-enabled: 1) {
  //for iPad PRO
  .root__container {
    height: 100%;

    .game-scores {
      height: 100%;

      &__container {
        height: 100%;

        .wrapper {
          width: 100% !important;
          height: 100%;

          .game-scores-table {
            //height: 100%;
            border: none;
          }


          .icon-cell {
            img {
              height: 80px !important;
            }
          }
        }
      }
    }
  }
}

.series180 {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}