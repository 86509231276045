$dark: #211f20;
$dark-gold: #847113;
.datePickerInp {
  margin-left: -20px;
}
.ls-dartboard {
  padding-top: 5px !important;
}
.live-page {
  position: relative;
  background-color: $dark;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 60px;
  min-height: 100vh;
  .backToHome{
    position: fixed;
    bottom: 20px;
    left: 20px;

  }
  .darts-match-list-row {
    height: 642px;
    overflow: auto;
  }
}

.container {
  .accordion {
    .content.active {
      padding: 0 !important;
      .accordion {
        margin: 0 !important;
      }
    }
  }
}

.picker {
  width: 100%;
  height: 100%;
  margin-left: 8px;
}

.p-0 {
  padding: 0 !important;
}

.calendar-btn,
.calendar-btn-enabled {
  background-color: grey;
  color: white;
  padding: 0.5rem !important;
}

.calendar-btn-enabled {
  background-color: $dark-gold !important;
}


