.sport-control {
   display: block !important;
}
.darts {
    height: 100%;
    &__container {
        justify-content: space-between;
        position: relative;
        &__board {
            width: 100%;
            height: 100%;
        }
        .darts__container__board--disabled {
          .c-Dartboard-bed {
            cursor: initial;
          }
        }
    }
    &__container &__details {
        text-align: center;
    }
    &__details {
        &__scoreboard-shootout {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 70%;
            position: relative;
            color: white;
            background-color: #454d55 !important;
            padding: 15px;
            & .row {
                padding-bottom: 0px;
            }
        }
        &__actions {
            margin-top: auto;
            width: 100%;
        }
    }

    .undo-button-container {
      position: absolute;
      bottom: 5%;
      left: 5%;

      .undo-button {
        text-transform: uppercase;
      }
    }
}

.not-started {
    width: 60%;
    margin: 0.5rem;
    line-height: 2.5rem;
    padding: 0.5rem;
    white-space: normal;
    color: white;
    font-size: 2rem;
}

.h-65 {
    height: 65%;
    overflow-y: auto;
}

.h-35 {
    height: 35%;
}

.bounce_out {
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.disabled {
    pointer-events: none;
}
