$text-color: #4d4c4c;

.mss-form {
  font-family: Sequel100Black65, sans-serif !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 80%;
  -webkit-box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 35px;
  background-color: #d2d2d2;
  z-index: 10;
  border: 0.5px black solid;
  overflow: scroll;

  .ui.label.mss-label {
    margin: 0 30px 0 0 !important;
  }

  .mss-input {

  }

  .ui.form input {
    font-family: "Montserrat", sans-serif !important;
    font-size: 15px !important;
    color: $text-color !important;
  }

  .ui.form input[type=file], .ui.form input[type=date] {
    font-family: Sequel100Black65, sans-serif;
    font-size: 11px;
    color: $text-color;
    height: 40px;
    padding-top: 10px;
  }


  .ui.form select {
    color: $text-color;
    position: relative;
    background: white url("../../../../img/arrow-down-icon.svg") no-repeat 98.5% !important;
    background-size: 12px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
}


.mss-label-control-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}

.mss-select {
  font-family: Sequel100Black65, sans-serif !important;
  font-size: 13px;
  height: 40px !important;
}

.mss-input {
  width: 100% !important;
  height: 40px !important;
  padding: 0 !important;
}

.ui.label.mss-label.mss-label-textarea {
  width: 120px !important;
  height: 40px !important;
}

.mms-text-editor {
  border-radius: 12px !important;
  height: 250px !important;
  color: $text-color !important;
}

.mss-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 5px !important;
  font-size: 14px !important;
  min-width: 120px !important;
  height: 100% !important;

}

.mss-image {
  img {
    display: block;
    width: 90%;
    max-width: 500px;
    max-height: 200px;
    border-radius: 12px;
    margin: 0 auto;
  }
}

.mss-show-btn {
  width: 100px !important;
}

.mss-submit {
  margin-top: 5px !important;
}

.ql {
  &-toolbar {
    border-radius: 0.29rem;
    border: 1px solid rgba(34, 36, 38, .15);
  }

  &-container {
    height: 80% !important;
    border-radius: 0.29rem;
    border: 1px solid rgba(34, 36, 38, .15);
    overflow: scroll;
  }

  &-editor {
    background-color: white;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
  }
}

.mss-submit {
  transform: translate(-5px, 8px);
}


.close-container {
  margin-left: 99%;
  margin-bottom: 10px;
  width: 30px;
  height: 25px;
  cursor: pointer;
  transform: translate(5px, -10px);
}

.leftright {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 15px;
  background-color: #414141;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all .3s ease-in;
}

.rightleft {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 15px;
  background-color: #414141;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all .3s ease-in;
}

.close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: #ff0010;
}

.close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: #ff0010;
}

.close-container:hover label {
  opacity: 1;
}

.file-placeholder {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}