.overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(43, 43, 43, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.form {
  width: 90%;
  background: #2B2B2B;
  height: 90%;
  overflow: auto;
  padding: 30px;
}

.tourColumn {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gameFirst {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.decor {
  width: 50px;
  height: 76px;
  background: #21ba45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWrapper {
  div {
   border-radius: 0 !important;
  }
}
