@media (min-width: 1281px) and (max-width: 1440px) {
  .pre-match-player {
    &-names {
      top: 0;
    }
    &__name, &__surname {
      font-size: 90px !important;
      line-height: 40px !important;
    }
    &-vs {
      font-size: 80px !important;
      line-height: 80px !important;
    }
  }
}
@media (min-width: 1441px) and (max-width: 1679px) {
  .pre-match-player {
    &-names {
      top: 10px;
    }

    &__name, &__surname {
      font-size: 90px !important;
      line-height: 40px !important;
    }
    &-vs {
      font-size: 90px !important;
      line-height: 90px !important;
    }
  }
}


@media (min-width: 1679px) and (max-width: 1919px) {
  .pre-match-player-names {
    .pre-match-player__name, .pre-match-player__surname {
      font-size: 93px;
      line-height: 50px;
    }
  }
  .pre-match-player-vs {
    padding: 0;
    font-size: 90px !important;
    line-height: 90px;
  }
}

@media (min-width: 2047px) and (max-width: 2558px) {
  .pre-match-player-names {
    padding-top: 65px;

    .pre-match-player__name, .pre-match-player__surname {
      font-size: 140px;
      line-height: 70px;
    }
  }
}

@media (min-width: 2559px) {
  .pre-match-player-names {
    padding-top: 85px;

    .pre-match-player__name, .pre-match-player__surname {
      font-size: 190px;
      line-height: 100px;
    }
  }
}