.overlay {
  background: rgba(54, 54, 54, 0.29);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  width: 800px;
  height: 600px;
  background: #2B2B2B;
  color: #FFFFFF;
  padding: 25px;
  overflow-y: auto;
}

.closeBtn {
  border: none;
  outline: none;
  background: transparent;
  color: #FFFFFF;
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  right: 0;
}

.traceInfo {
  margin-bottom: 40px;
}
.infoRow {
  display: flex;
  column-gap: 5px;
}