.player-scoreboard-body {
  color: white;
  margin-top: 20px !important;

  .row {
    font-size: 44px;
    line-height: 30px;

    .col {
      text-align: center;
    }

    .scored-col {
      width: 90%;

    }

    .remaining-score-col {
      font-weight: bold;
      text-align: right;

    }
  }
  .shootout-scores-row .column{
    justify-content: flex-end !important;
  }
}

