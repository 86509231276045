.loggingMenu {
  margin-top: 10px;
  background: #2B2B2B;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  position: absolute;
  left: 0;
  width: 130px;
  display: none;
  transition: .5s;
  top: 27px;
  list-style: none;
}

.menuItem {
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;

}
.menuItem:hover {
  text-decoration: underline;
  cursor: pointer;
}