$border: 2px solid white;
$dark:#211f20;
.game-item {
    cursor: pointer;
    border-top: $border;
    background-color: $dark;
    color: white;
    width: 100%;
    .content {
        display: flex;
        .status-inner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .scores-content {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            .players {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .scores {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .score-separator {
                    padding-top: 2px;
                    padding-bottom: 4px;
                }
            }
        }
    }

    hr {
        font-size: large;
        margin: 0 !important;
    }
    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: $border;
        font-size: smaller !important;
        word-wrap: break-word;
        padding-left: 10px;
        padding-right: 10px;
    }
    .name {
        font-size: smaller !important;
        padding-left: 0.5em;
    }
}

.game-item--active {
  background: linear-gradient(90deg, rgba(224, 224, 224, 0.4) 0%, rgba(224, 224, 224, 0.15) 40%, rgba(224, 224, 224, 0) 100%);
}