.player-scoreboard-body {
  color: white;

  .row {
    font-size: 44px;
    line-height: 30px;
    justify-content: flex-end !important;

    .col {
      text-align: center;
    }

    .scored-col {
      width: 90%;
    }

    .remaining-score-col {
      font-weight: bold;
      text-align: right;
    }
  }
}
