@media (max-width: 1440px) {
  .animation-control {
    flex-direction: row !important;
    padding-left: 15px;
  }
}

@media (max-width: 1681px) {
  .animation-control {
    top: 2% !important;
    width: 230px;
    left: 5.5% !important;
    justify-content: space-between;
    flex-direction: row !important;
  }
  .pre-match-footer {
    bottom: -8px !important;
  }
}