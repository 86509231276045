$dark:#211f20;
$dark-gold:#847113;
.np {
    padding: 0 !important;
}

.centered-v {
    display: flex !important;
    align-items: center !important;
}

.icon {
    text-align: center;
}
.download-csv-btn {
    display: flex;
    justify-content: center;
}
.round-name {
    background-color: white;
    border: 0.1em solid white;
    border-radius: 0.5em;
    color: black;
}

.darts {
    padding-left: 50px !important;
    padding-right: 50px !important;
    &__live {
        position: absolute;
        top: 30%;
        left: 40%;
        color: white;
        font-size: xx-large;
        z-index: 1;
        text-shadow: 0.2rem 0.2rem 0.4rem black;
    }
    &__load-btn-inner{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    &__load-link {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 250px;
        height: 40px;
        background-color: rgb(218, 21, 21);
        cursor: pointer;
        color: white;
        padding: 10px 20px;
        border-radius: 3px;
        transition: linear .2s;
        &:hover {
            color: white;
            background-color: #cbba5a;;
        }

    }
    &__container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    &__container-center {
        justify-content: center !important;
        height: 90%;
    }
    &__container>&__board {
        width: 15em;
        height: 15em;
    }
    &__round {
        margin-top: 20px;
    }
    &__scoreboard {
        position: relative;
        width: 30%;
        color: white;
        background-color: $dark !important;
        padding: 10px;
        & .row {
            padding-bottom: 0px;
        }
    }
}
.btn-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}