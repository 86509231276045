.start-page {
  position: fixed;
  display: flex;
  width: 85vw;
  height: 85vh;
  justify-content: center;
  align-items: center;
  background: #000000;
  left: 7.5%;
  overflow: hidden;

  .wrapper {
    z-index: 100;
    position: absolute;
    left: 50%;
    transform: translate(-47%, -164px);
    width: 933px;
    height: 1148px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__logo-wrapper {
    display: block;
    width: 90%;
  }

  &__logo-inner {
    position: absolute;
    overflow: hidden;
  }
}

.start-page-last-matches {
  padding-left: 10px;
  position: absolute;
  left: 50%;
  bottom: 10% !important;
  transform: translateX(-50%);
  font-family: "Sequel100Black75", sans-serif;
  font-size: 50px;
  line-height: 60px;
  overflow: hidden;
  width: 850px;
  padding-right: 50px;
  color: white;
  text-transform: uppercase;
  text-align: end;
  font-style: italic;
  span {
    color: #c6a81c;
  }
}

.fadingEffect {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: black;
  -moz-animation: showHide 3.5s ease-in alternate; /* Firefox */
  -webkit-animation: showHide 3.5s ease-in alternate; /* Safari and Chrome */
  -ms-animation: showHide 3.5s ease-in alternate; /* IE10 */
  -o-animation: showHide 3.5s ease-in alternate; /* Opera */
  animation: showHide 3.5s ease-in alternate;
}

@-webkit-keyframes showHide {
  0% {
    width: 100%
  }
  20% {
    width: 0%
  }
  80% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes showHide {
  0% {
    width: 100%
  }
  20% {
    width: 0%
  }
  80% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-ms-keyframes showHide {
  0% {
    width: 100%
  }
  20% {
    width: 0%
  }
  80% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-o-keyframes showHide {
  0% {
    width: 100%
  }
  20% {
    width: 0%
  }
  80% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes showHide {
  0% {
    width: 100%
  }
  20% {
    width: 0%
  }
  80% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
